html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.3s ease;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hero.is-fullheight {
  /* background: radial-gradient(black 15%, transparent 16%) 0 0, radial-gradient(black 15%, transparent 16%) 8px 8px,
   radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 0 1px,
    radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px; */
  background-color: #0f1dff;
  /* background-size: 16px 16px; */
}

.navbar-item img {
  max-height: auto !important;
}

.padding-top-40 {
  padding-top: 40px;
}

.zero-padding-tb {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.white-container{
  background-color: #ffffff;
  color: #000;
  padding: 20px;
  border-radius: 4px;
}
span.delete {
  position: absolute;
  right: 10px;
  top: 10px;
}
span.edit {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 10px;
  background: #999;
  color: #fff;
  border-radius: 4px;
}
span.edit:hover {
  background: #1a1a1c;

}
span.delete:hover {
  background: red;
}

@media screen and (min-width: 1024px) {
  .navbar-link.is-active,
  a.navbar-item.is-active {
    color: #ffffff;
    background: #1a1a1c !important;
    border-radius: 4px;
  }
}
